import React, {Fragment, lazy, Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { ContextLoader } from "../shared/loaders";

const LandingPageComponent = lazy(() => import("../pages/landingpage"));

const AppNavigator = () => {
  return (
	<Fragment>
		<Router>
			<Suspense fallback={ContextLoader()}>
				<Switch>
					<Route path="/" component={LandingPageComponent} exact={true}/>
				</Switch>
			</Suspense>
		</Router>
	</Fragment>
  );
};

export default AppNavigator